<template>
  <v-app fill-height class="w-100 grey lighten-4">
    <main class="w-100 my-3 fill-height mx-auto">
      <v-container fluid fill-height>
        <section class="w-100 d-flex align-items-center justify-content-center fill-height">
          <div class="col-sm-6 col-12 rounded-lg shadow-sm d-flex flex-column white fill-height">
            <div class="mt-5 w-75 me-2">
              <!--              <span class="f18 font-weight-bold"> {{ $t('Login to') }} </span>-->
              <!--              <span class="f18 font-weight-bold d-block mt-3">{{ $t('your account') }}</span>-->
              <span
                  class="f18 font-weight-bold d-block mt-3">{{
                  this.$store.getters.clientType === 'CLIENT' ?
                      $t('clint account') : $t('doctor account')
                }}
              </span>
            </div>

            <div class="mt-5">
              <span class="grey--text text--darken-1 font-weight-normal">
                {{ $t('The verification code will be sent to your mobile number') }}
              </span>
            </div>

            <div class="d-flex flex-row justify-content-between mt-auto right-to-left">
              <div class="w-75">
                <v-text-field outlined v-model="mobile" type="tel" :label="$t('Mobile number')" class="mx-1 text-center"
                              :rules="PhoneNumberRules" required/>
              </div>
              <div class="w-25">
                <v-text-field outlined v-model="prenumber" type="tel" class="mx-1 text-center left-to-right"
                              :rules="PrefixRules" required readonly/>
              </div>
            </div>
            <div class="mt-1">
              <div class="mt-2 d-flex flex-row align-items-stretch">
                <v-btn color="primary" large class="col-8 flex-fill mx-2 13"
                       @click="validate()" :loading="submit_loading"
                       :disabled="submit_loading">
                  {{ $t('Confirm') }}
                </v-btn>

                <v-btn color="red" large
                       class="col-4 text-white flex-fill mx-2 f13" @click="backToAuth()"
                       :loading="submit_loading" :disabled="submit_loading">
                  {{ $t("Canceled") }}
                </v-btn>
              </div>
            </div>
          </div>
        </section>
      </v-container>
    </main>
  </v-app>
</template>
<script>
import {cache} from "@vue/cli-service/lib/config/terserOptions";

export default {
  data() {
    return {
      submit_loading: false,

      mobile: null,
      prenumber: "+98",

      PrefixRules: [
        (v) => !!v || this.$t('Enter country code is required'),
        (v) => !!v.startsWith("+") || this.$t('Country code must start with +'),
      ],

      PhoneNumberRules: [
        (v) => !!v || this.$t('Enter the mobile number'),
        (v) => (v && v.length >= 10) || this.$t('Mobile number must be at least 10 characters'),
        (v) => (v && v.length <= 11) || this.$t('Mobile number must be at less than 11 characters'),
      ],
    };
  },

  methods: {
    validate() {
      if (!this.mobile) {
        this.$toast.error(this.$t("Please enter your mobile number"), {rtl: this.$vuetify.rtl})
      } else if (this.mobile.length < 10) {
        this.$toast.error(this.$t("Mobile number must be at least 10 characters"), {rtl: this.$vuetify.rtl})
      } else if (this.mobile.length > 11) {
        this.$toast.error(this.$t("Mobile number must be at less than 11 characters"), {rtl: this.$vuetify.rtl})
      } else {
        if (this.mobile.startsWith("0")) {
          this.mobile = this.mobile.slice(1, this.mobile.length);
        }
        this.Login();
      }
    },
    Login() {
      if (this.$store.getters.clientType === "CLIENT") {
        this.submit_loading = true;
        this.$store
            .dispatch("loginUser", {
              mobileNumber: `${this.prenumber}${this.mobile}`,
            })
            .then((response) => {
              if (response.status === 200) {
                this.submit_loading = false;
                this.$store.commit(
                    "update_mobileNumber",
                    `${this.prenumber}${this.mobile}`
                );
                this.$router.push("/verification-code");
              }
            })
            .catch((err) => {
              this.submit_loading = false;
              this.$swal({
                icon: "warning",
                text: err ? err.response.data.detail : this.$t("Error"),
                confirmButtonText: this.$t('OK'),
              });
            });
      } else if (this.$store.getters.clientType === "DOCTOR") {
        this.submit_loading = true;
        this.$store
            .dispatch("loginDoctor", {
              mobileNumber: `${this.prenumber}${this.mobile}`,
            })
            .then((response) => {
              if (response.status === 200) {
                this.submit_loading = false;
                this.$store.commit(
                    "update_mobileNumber",
                    `${this.prenumber}${this.mobile}`
                );
                this.$router.push("/verification-code");
              }
            })
            .catch((err) => {
              this.submit_loading = false;
              this.$swal({
                icon: "warning",
                text: err ? err.response.data.detail : this.$t("Error"),
                confirmButtonText: this.$t('OK'),
              });
            });
      }
    },
    backToAuth() {
      this.$store.dispatch("logout");
      this.$router.push("/auth")
    },
  },

  created() {
    if (this.$store.getters.clientType === "") {
      this.$router.push("/auth")
    }
    if (this.$store.getters.isLoggedIn) {
      if (!this.$store.getters.authenticated) {
        if (this.$store.getters.clientType === "CLIENT") {
          this.$router.push("/register-client");
        } else if (this.$store.getters.clientType === "DOCTOR") {
          this.$router.push("/register-specialist");
        }
      } else if (this.$store.getters.authenticated) {
        if (this.$store.getters.clientType === "CLIENT") {
          this.$router.push("/c/home")
        } else if (this.$store.getters.clientType === "DOCTOR") {
          this.$router.push("/s/home");
        }
      }
    }
  },
};
</script>
<style scoped>
#box-shadow {
  box-shadow: 20px 20px 100px #e4f7ff;
}
</style>
